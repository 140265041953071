export const APP_VERSION = '1.41.1';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  ONBOARDING: {
    options: {
      primaryColor: '#773DFF',
      zIndex: 100000,
    },
    beacon: {
      display: 'none',
      position: 'absolute',
      top: 20,
      left: 10,
    },
    buttonClose: {
      display: 'none',
    },
    buttonNext: {
      fontSize: '1rem',
      width: '45%',
      height: '2.5rem',
      color: '#fff',
      backgroundColor: '#773DFF',
      borderRadius: 4,
      left: '1.25rem',
      bottom: '1.75rem',
    },
    buttonSkip: {
      fontSize: '.75rem',
      color: '#0083B2',
      position: 'absolute',
      right: '1.25rem',
      bottom: '2rem',
    },
    tooltip: {
      padding: '1.25rem',
      maxWidth: 'calc(100vh/13.75rem)',
      maxHeight: 'calc(100vw/16.875rem)',
    },
    tooltipContainer: {
      textAlign: 'left',
    },
    tooltipTitle: {
      fontFamily: 'Roboto',
      fontSize: '1.5rem',
      color: '#773DFF',
    },
    tooltipContent: {
      fontFamily: 'Roboto',
      fontSize: 16,
      color: '#67777E',
      padding: '.5rem 0 1.5rem',
    },
  },
};

export const ONBOARDING_STEPS = {
  client: [
    {
      target: '.step1',
      title: 'Create a new Client',
      content: 'Click here to create a new Client!',
      placement: 'bottom',
      hideFooter: true,
      disableBeacon: true,
    },
    {
      target: '.step2',
      title: 'Client Info',
      content: 'Fill in the required Client info.',
      placement: 'right',
      disableBeacon: true,
      spotlightPadding: 0,
    },
    {
      target: '.step3',
      title: 'Create Client',
      content: 'Creating a Client will send an invite to them',
      placement: 'top',
      disableBeacon: true,
      // hideFooter: true,
    },
    {
      target: '.stepProtocols',
      title: 'Deploy Protocols',
      content: 'Click the Protocols Tab to view, create, or deploy your protocols',
      placement: 'right',
      hideFooter: true,
      disableBeacon: true,
      spotlightPadding: 0,
    },
  ],
  protocol: [
    {
      target: '.stepProtocol0',
      title: 'Deploy a Protocol',
      content: 'Here you can deploy premade Protocols to your Clients',
      placement: 'left',
      disableBeacon: true,
      hideFooter: true,
    },
    {
      target: '.stepDevice0',
      title: 'Select Device',
      content: 'You can select the Devive the Protocol can be executed with.',
      placement: 'left',
      disableBeacon: true,
      hideFooter: true,
    },
    {
      target: '.step1',
      title: 'Deploy to Session',
      content:
        'The protocol can be deployed to a new Session or an existing Session. Please create a new Session.',
      placement: 'left',
      disableBeacon: true,
      hideFooter: true,
    },
    {
      target: '.step2',
      title: 'Customize Protocol',
      content: 'You can customize any Protocol to fit your Client`s specific needs',
      placement: 'left',
      disableBeacon: true,
      hideFooter: true,
    },
    {
      target: '.step3',
      title: 'Protocol Settings',
      content: 'You can customize duration, protocol name, etc here',
      placement: 'right',
      disableBeacon: true,
    },
    {
      target: '.step4',
      title: 'Event Settings',
      content: 'You can customize the threshold values for events.',
      placement: 'left',
      disableBeacon: true,
    },
    {
      target: '.step5',
      title: 'Save Protocol',
      content: 'Click here to save the customized Protocol',
      placement: 'top',
      disableBeacon: true,
      hideFooter: true,
    },
    {
      target: '.step6',
      title: 'Session Info',
      content: 'Give your Session a name and details',
      placement: 'right',
      disableBeacon: true,
    },
    {
      target: '.step7',
      title: 'Assign Client',
      content: 'Select the Client that you have just created.',
      placement: 'right',
      spotlightPadding: 0,
      disableBeacon: true,
    },
    {
      target: '.step8',
      title: 'Select Recurrences',
      content:
        'Find the type of recurrence that fits your Client. You can choose the days or from the calendar.',
      placement: 'left',
      spotlightPadding: 20,
      disableBeacon: true,
    },
    {
      target: '.step9',
      title: 'Save Your Session',
      content:
        'Now you can save your Session. Your Client will be able to receieve the Session on the days you chose above.',
      placement: 'top',
      disableBeacon: true,
      // hideFooter: true,
    },
    {
      target: '.step10',
      title: 'Session Selections',
      content: 'Here you can add other Protocols or Questionnaires to the Session',
      placement: 'right',
      disableBeacon: true,
      spotlightPadding: 0,
    },
    {
      target: '.step11',
      title: 'Completing Session Creation',
      content:
        'Click here to complete the save and activate the Session so your Client can view it on the Dive App.',
      placement: 'top',
      disableBeacon: true,
      hideFooter: true,
    },
    {
      target: '.step12',
      title: 'Session List',
      content: 'Click here to view and manage your Sessions.',
      placement: 'right',
      spotlightPadding: 0,
      disableBeacon: true,
      hideFooter: true,
    },
  ],
  session: [
    {
      target: '.step0',
      title: 'Session List ',
      content: 'Here you can view your Sessions and create new ones.',
      placement: 'left',
      disableBeacon: true,
      // hideFooter: true,
    },
    {
      target: '.stepDevices',
      title: 'Device Management',
      content:
        'If you have a device, click the Device tab for a guide, otherwise hit next to complete the ocnboaring guide.',
      placement: 'right',
      spotlightPadding: 0,
      disableBeacon: true,
      hideFooter: false,
    },
    {
      target: '.stepResources',
      title: 'Onboarding Complete!',
      content:
        'Congraulations, that`s the end of the onboading guide! Please take a look at our additional resources if you require more help!',
      placement: 'right',
      disableBeacon: true,
      spotlightPadding: 0,
    },
  ],
  device: [
    {
      target: '.step0',
      title: 'Add Device',
      content: 'Pressing this button will allow you to add a Device.',
      placement: 'right',
      disableBeacon: true,
      hideFooter: true,
    },
    {
      target: '.step1',
      title: 'Device Selection',
      content:
        'Here you can choose which device to add to your account, each device will have a different workflow. Click Next to continue.',
      placement: 'left',
      disableBeacon: true,
      spotlightPadding: 0,
      spotlightClicks: false,
    },
    {
      target: '.step2',
      title: 'Pruchase Devices',
      content: 'You can purchase Neurosity or BrainBit device here. Please click Next to continue.',
      placement: 'bottom',
      disableBeacon: true,
      spotlightPadding: 0,
      spotlightClicks: false,
    },
    {
      target: '.stepResources',
      title: 'Onboarding Complete!',
      content: 'Congraulations, that`s the end of the onboading guide!',
      placement: 'right',
      disableBeacon: true,
      spotlightPadding: 0,
    },
  ],
};

export const DEVICE_PURCHASE_DETAILS = {
  devices: [
    {
      deviceName: 'Neurosity Crown 3',
      deviceImg: '/static/images/crown-device.svg',
      deviceImgAlt: 'Neurosity Crown',
      details: [
        '8 EEG sensor locations: CP3, C3, F5, PO3, PO4, F6, C4, CP4',
        'Reference and bias sensor locations T7 and T8',
        '256 Hz Sampling rate',
        'Passive + active sensors',
        'Flexible dry sensors: Silver/Silver Chloride',
        'Ultra-low noise floor of 0.25 uVrms',
      ],
      specsLink: 'https://neurosity.co/tech-specs',
      purchaseQuantity: true,
      purchasePrice: '$999',
      purchaseText: 'USD / Unit + Shipping',
      purchaseLink:
        'https://store.neurosity.co/cart/39275559845939:${qty}?attributes[partner]=divergence&checkout[email]=${email}&discount=divergence',
    },
    {
      deviceName: 'BrainBit Headband',
      deviceImg: '/static/images/brainbit-device.svg',
      deviceImgAlt: 'Brainbit Device',
      details: [
        '4 EEG sensor locations: T3, T4, O1, O2',
        '15 Hour Active Battery',
        '4.0 LE Bluetooth Connection',
        'Channel sampling rate — 250 Hz',
        'Records raw EEG data both in a bipolar and monopolar mode',
        'Built in noise cancellation',
      ],
      specsLink: 'https://brainbit.com/media/1053/brainbit-specification.pdf',
      purchaseQuantity: true,
      rentalQuantity: false,
      rentalPrice: '$59',
      rentalText: 'USD / month plus $119 USD (one time setup fee)',
      rentalLink: 'https://buy.stripe.com/dR65m6esJaUU6Jy5kk?prefilled_email=${email}',
      purchasePrice: '$499',
      purchaseText: 'USD / Unit + Shipping',
      purchaseLink:
        'https://store.brainbit.com/cart/33522943524997:${qty}?attributes[partner]=divergence&checkout[email]=${email}',
    },
    {
      deviceName: 'BrainBit Flex',
      deviceImg: '/static/images/brainbit-flex-device.svg',
      deviceImgAlt: 'Brainbit Flex Device',
      details: [
        '4 EEG sensors for a 10-20 international scheme on a textile cap',
        '15 Hour Active BAttery',
        '4.0 LE Bluetooth Connection',
        'Channel sampling rate — 250 Hz',
        'Records raw EEG data both in a bipolar and monopolar mode',
        'Built in noise cancellation',
      ],
      specsLink: null,
      purchaseQuantity: false,
      rentalQuantity: false,
      rentalPrice: '$79',
      rentalText: 'USD / month plus $99 USD (one time setup fee)',
      rentalLink: 'https://buy.stripe.com/6oEbKu70he766JyeUV?prefilled_email=${email}',
      purchasePrice: '$750',
      purchaseText: 'USD / Unit + Shipping',
      purchaseLink:
        'https://store.brainbit.com/products/brainbit-flex-dry-electrodes?sca_ref=2640784.mylCtR7JUI&sca_source=Devergence',
    },
    {
      deviceName: 'Callibri',
      deviceImg: '/static/images/callibri-device.png',
      deviceImgAlt: 'Callibri Device',
      details: [
        'Wearable device embedded with ECG, EMG and EEG sensors',
        'Lightweight and easily attached to various areas of the body',
        '24 hour battery life',
        'Capable of utilizing electrostimulation for pain relief and muscle stimulation',
        'Lightweight and sweatproof',
      ],
      specsLink: 'https://callibri.com/specification/',
      purchaseQuantity: false,
      purchasePrice: '$399',
      purchaseText: 'USD / Unit + Shipping',
      purchaseLink:
        'https://store.brainbit.com/products/callibri-baseball-cap?sca_ref=2640784.mylCtR7JUI&sca_source=Divergence',
    },
    {
      deviceName: 'Synchroni Uno',
      deviceImg: '/static/images/synchroni-uno-device.png',
      deviceImgAlt: 'Synchroni Uno Device',
      details: [
        '1 sensor that can be placed anywhere for EEG or ECG measurement',
        'Lightweight and easily attached to various areas of the body',
        'Place inside any standard baseball cap or stylish hat',
      ],
      specsLink:
        'https://synchroni.co/wp-content/uploads/2024/09/Synchroni-Uno-Product-User-Guide-SEP-2024-.pdf',
      purchaseQuantity: false,
      purchasePrice: '$399',
      purchaseText: 'USD / Unit + Shipping',
      purchaseLink:
        'https://shop.synchroni.co/products/synchroni-uno?utm_source=Divergence&utm_medium=referral&utm_campaign=partner',
    },
    {
      deviceName: 'Synchroni Trio',
      deviceImg: '/static/images/synchroni-trio-device.png',
      deviceImgAlt: 'Synchroni Trio Device',
      details: [
        '2 EEG sensors that can be placed anywhere',
        '1 ECG sensor that can be placed on the chest to measure things like HRV',
        'Light-weight and comfortable form factor',
        'Your choice of any size (small, medium or large) 10-10 or 10-20 cap',
      ],
      specsLink:
        'https://synchroni.co/wp-content/uploads/2024/09/Synchroni-Trio-Product-User-Guide-SEP-2024.pdf',
      purchaseQuantity: false,
      purchasePrice: '$540',
      purchaseText: 'USD / Unit + Shipping',
      purchaseLink:
        'https://shop.synchroni.co/products/synchroni-trio?utm_source=Divergence&utm_medium=referral&utm_campaign=partner',
    },
  ],
};

// DEVICES INFO FOR SUPPORTED DEVICES MANAGEMENT

export const SUPPORTED_DEVICES = {
  Neurosity: {
    deviceType: 'Neurosity',
    deviceName: 'Neurosity Crown 3',
    deviceImg: '/static/images/crown-device.svg',
    logoIcon: '/static/images/device-logo.svg',
    deviceImgAlt: 'Neurosity Crown',
    details: [
      '8 EEG sensor locations: CP3, C3, F5, PO3, PO4, F6, C4, CP4',
      'Reference and bias sensor locations T7 and T8',
      '256 Hz Sampling rate',
      'Passive + active sensors',
      'Flexible dry sensors: Silver/Silver Chloride',
      'Ultra-low noise floor of 0.25 uVrms',
    ],
    specsLink: 'https://neurosity.co/tech-specs',
    channels: 8,
    channelNames: ['CP3', 'C3', 'F5', 'PO3', 'PO4', 'F6', 'C4', 'CP4'],
  },
  BrainBit: {
    deviceType: 'BrainBit',
    deviceName: 'BrainBit Headband',
    deviceImg: '/static/images/brainbit-device.png',
    logoIcon: '/static/images/brainbit-logo-icon.svg',
    deviceImgAlt: 'Brainbit Device',
    details: [
      '4 EEG sensor locations: T3, T4, O1, O2',
      '15 Hour Active BAttery',
      '4.0 LE Bluetooth Connection',
      'Channel sampling rate — 250 Hz',
      'Records raw EEG data both in a bipolar and monopolar mode',
      'Built in noise cancellation',
    ],
    specsLink: 'https://brainbit.com/media/1053/brainbit-specification.pdf',
    channelNames: ['T3', 'T4', 'O1', 'O2'],
    channels: 4,
    manufacturer: 'BrainBit Inc.',
    model: 'BrainBit',
    modelName: 'Headband',
    modelVersion: null,
    osVersion: null,
    samplingRate: 250,
  },
  'BrainBit Flex': {
    deviceType: 'BrainBit Flex',
    deviceName: 'BrainBit Flex',
    deviceImg: '/static/images/brainbit-flex-device.svg',
    logoIcon: '/static/images/brainbit-logo-icon.svg',
    deviceImgAlt: 'Brainbit Device',
    details: [
      '10-20 Montage System',
      '15 Hour Active BAttery',
      '4.0 LE Bluetooth Connection',
      'Channel sampling rate — 250 Hz',
      'Records raw EEG data both in a bipolar and monopolar mode',
      'Built in noise cancellation',
    ],
    channelNames: [
      'Fp1',
      'Fpz',
      'Fp2',
      'F7',
      'F3',
      'Fz',
      'F4',
      'F8',
      'T3',
      'C3',
      'Cz',
      'C4',
      'T4',
      'T5',
      'P3',
      'Pz',
      'P4',
      'T6',
      'O1',
      'Oz',
      'O2',
    ],
    channels: 4,
    manufacturer: 'BrainBit Inc.',
    model: 'BrainBit',
    modelName: 'Flex',
    modelVersion: null,
    osVersion: null,
    samplingRate: 250,
  },
  Callibri: {
    deviceType: 'Callibri',
    deviceName: 'Callibri',
    deviceImg: '/static/images/callibri-device.png',
    logoIcon: '/static/images/callibri-logo-small.png',
    deviceImgAlt: 'Callibri Device',
    details: [
      'Wearable device embedded with ECG, EMG, EEG and respiratory sensors',
      'Capable of monitoring physiological data on muscle movement and tension, breathing & heart rates',
      'Lightweight, sweatproof and easily attached to various areas of the body',
      '24 hour battery life',
    ],
    channelNames: [
      'Fp1',
      'Fpz',
      'Fp2',
      'F7',
      'F3',
      'Fz',
      'F4',
      'F8',
      'T3',
      'C3',
      'Cz',
      'C4',
      'T4',
      'T5',
      'P3',
      'Pz',
      'P4',
      'T6',
      'O1',
      'Oz',
      'O2',
    ],
    channels: 1,
    manufacturer: 'BrainBit Inc.',
    model: 'Callibri',
    modelName: 'Callibri',
    modelVersion: null,
    osVersion: null,
    samplingRate: 250,
    specsLink: 'https://callibri.com/specification/',
  },
  'Synchroni Uno': {
    deviceType: 'Synchroni Uno',
    deviceName: 'Synchroni Uno',
    deviceImg: '/static/images/synchroni-uno-device.png',
    logoIcon: '/static/images/synchroni-logo.png',
    deviceImgAlt: 'Synchroni Uno Device',
    details: [
      '1 sensor that can be placed anywhere for EEG or ECG measurement',
      'Lightweight and easily attached to various areas of the body',
      'Place inside any standard baseball cap or stylish hat',
    ],
    channelNames: [
      'Fp1',
      'Fpz',
      'Fp2',
      'F7',
      'F3',
      'Fz',
      'F4',
      'F8',
      'T3',
      'C3',
      'Cz',
      'C4',
      'T4',
      'T5',
      'P3',
      'Pz',
      'P4',
      'T6',
      'O1',
      'Oz',
      'O2',
    ],
    channels: 1,
    manufacturer: 'Synchroni Scientific Instruments Inc.',
    model: 'Uno',
    modelName: 'Uno',
    modelVersion: null,
    osVersion: null,
    samplingRate: 250,
    specsLink:
      'https://synchroni.co/wp-content/uploads/2024/09/Synchroni-Uno-Product-User-Guide-SEP-2024-.pdf',
  },
  'Synchroni Trio': {
    deviceType: 'Synchroni Trio',
    deviceName: 'Synchroni Trio',
    deviceImg: '/static/images/synchroni-trio-device.png',
    logoIcon: '/static/images/synchroni-logo.png',
    deviceImgAlt: 'Synchroni Trio Device',
    details: [
      '2 EEG sensors that can be placed anywhere',
      '1 ECG sensor that can be placed on the chest to measure things like HRV',
      'Light-weight and comfortable form factor',
      'Your choice of any size (small, medium or large) 10-10 or 10-20 cap',
    ],
    channelNames: [
      'Fp1',
      'Fpz',
      'Fp2',
      'F7',
      'F3',
      'Fz',
      'F4',
      'F8',
      'T3',
      'C3',
      'Cz',
      'C4',
      'T4',
      'T5',
      'P3',
      'Pz',
      'P4',
      'T6',
      'O1',
      'Oz',
      'O2',
    ],
    channels: 2,
    manufacturer: 'Synchroni Scientific Instruments Inc.',
    model: 'Trio',
    modelName: 'Trio',
    modelVersion: null,
    osVersion: null,
    samplingRate: 250,
    specsLink:
      'https://synchroni.co/wp-content/uploads/2024/09/Synchroni-Trio-Product-User-Guide-SEP-2024.pdf',
  },
};

export const SUPPORTED_FEEDBACK_RESPONSES = {
  Audio: {
    feedbackType: 'audio',
    feedbackTypeName: 'Audio Feedback',
    feedbackTypeImg: '/static/images/headphone.svg',
    feedbackTypeAlt: 'audio response',
    feedbackTypeDetails:
      "Create an Audio Feedback to use in a Client's Protocol. Audio Feedback Responses will be played during your Client's Protocol and will increase and decrease in volume depending on the success of the Protocol.",
  },
  Video: {
    feedbackType: 'video',
    feedbackTypeName: 'Video Feedback',
    feedbackTypeImg: '/static/images/av.svg',
    feedbackTypeAlt: 'video response',
    feedbackTypeDetails:
      "Create a Video Feedback to use in a Client's Protocol. Use a YouTube URL that will be played during your Client's Protocol, the feedback response will increase and decrease in volume as well as brighten depending on the success of the Protocol.",
  },
};

export const PLATFORM_REGIONS = [
  {
    label: 'United States',
    icon: '/static/images/flag/usa.svg',
    href: 'https://app.divergenceneuro.com',
  },
  {
    label: 'Canada',
    icon: '/static/images/flag/canada.svg',
    href: 'https://app.divergenceneuro.ca',
  },
  {
    label: 'Europe',
    icon: '/static/images/flag/europe.svg',
    href: 'https://app-eu.divergenceneuro.com',
  },
];

export const DYSREGULATION_TEXTS = {
  SCORES: {
    GREEN:
      "These latent traits suggest likelihood of little or no impairment expressed or experienced with respect to [indexName], in that this individual's scores suggest that they are likely functioning at or near their capacity in this domain.",
    YELLOW:
      "These latent traits suggest likelihood of mild to moderate impairment expressed or experienced with respect to [indexName], in that this individual's scores suggest that they are likely functioning noticeably below their capacity in this domain.",
    RED: "These latent traits suggest potential for clinically significant impairment expressed or experienced with respect to [indexName], in that this individual's scores suggest that they are likely functioning significantly below their capacity in this domain.",
  },
  TRAITS: {
    EMOTIONAL: {
      TITLE: 'Emotional Regulation: Latent Traits',
      DESCRIPTION:
        'The statistics comprising this index are often associated with the neurofunctional capacity to effectively manage the effective regulation of emotions. Of these X indicators, Y fall outside of a normative range. The more indicators that fall in this clinically meaningful range, the greater the potential to experience difficulties with the process of emotional regulation. For further detail, review the contributing components below.',
      FOOTNOTE:
        'These indicators comprise a non-exhaustive list of factors that may contribute to emotional regulation. They are intended as an aide to the generation of clinical hypotheses with respect to neurofunctional assessment and treatment options. Consider this information along with pertinent research and other clinical resources regarding the neurofunctional bases of emotional regulation.',
    },
    SLEEP: {
      TITLE: 'Sleep Health: Latent Traits',
      DESCRIPTION:
        'The statistics comprising this index are associated with the neurofunctional capacity for effective sleep architecture. Of these X indicators, Y fall outside of a normative range. The more indicators that fall in this clinically meaningful range, the greater the potential to experience difficulties with the sleep cycle. For further detail, review the contributing components below.',
      FOOTNOTE:
        'These indicators comprise a non-exhaustive list of factors that may contribute to sleep dysregulation. They are intended as an aide to the generation of clinical hypotheses with respect to neurofunctional assessment and treatment options. Consider this information along with pertinent research and other clinical resources regarding the neurofunctional bases of sleep dysregulation.',
    },
    COGNITIVE: {
      TITLE: 'Cognitive Performance: Latent Traits',
      DESCRIPTION:
        'The statistics comprising this index are often associated with various aspects of cognitive performance. Of these X indicators, Y fall outside of a normative range. The more indicators that fall in this clinically meaningful range, the greater the potential to experience difficulties with attention, concentration, memory, and other aspects of cognitive performance. For further detail, review the contributing components below.',
      FOOTNOTE:
        'These indicators comprise a non-exhaustive list of factors that may contribute to challenges in cognitive performance. They are intended as an aide to the generation of clinical hypotheses with respect to neurofunctional assessment and treatment options. Consider this information along with pertinent research and other clinical resources regarding the neurofunctional bases of cognitive performance.',
    },
    TRAUMA: {
      TITLE: 'Trauma Markers: Latent Traits',
      DESCRIPTION:
        'The statistics comprising this index are often associated with a history of trauma and/or chronic stress. Of these X indicators, Y fall outside of a normative range. The more indicators that fall in this clinically meaningful range, the greater the potential for trauma spectrum or stress-related symptoms. For further detail, review the contributing components below.',
      FOOTNOTE:
        'These indicators comprise a non-exhaustive list of factors that may contribute to trauma-related expressed traits. They are intended as an aide to the generation of clinical hypotheses with respect to neurofunctional assessment and treatment options. Consider this information along with pertinent research and other clinical resources regarding the neurofunctional bases of trauma-related expressed traits.',
    },
  },
};

export const CANCELLATION_TEXTS = {
  GENERAL: '',
  NMI: {
    DESCRIPTION:
      'Removing this Package will disable any NMI Protocols used in any Sessions. Your Clients will not be able to execute NMI Protocols after removing this Package.',
    REASONS: [
      'It is too expensive for me.',
      'I don’t use the protocols enough.',
      'It does not address my needs.',
    ],
  },
  'TRUEBEARING STANDARD': {
    DESCRIPTION:
      'Removing this Package will disable any True Bearing Protocols used in any Sessions. Your Clients will not be able to execute True Bearing Protocols after removing this Package.',
    REASONS: [
      'It is too expensive for me.',
      'I don’t use the protocols enough.',
      'It does not address my needs.',
    ],
  },
  THERAQPLUS: {
    DESCRIPTION:
      'Removing this Package will disable any TheraQ analysis. You will not be able to see any additional TheraQ data.',
    REASONS: [
      'It is not useful to me. ',
      'It is too expensive for me.',
      'I use other alternatives for assessments.',
    ],
  },
};

export const APP_STORE_LINKS = {
  APPLE: 'https://apps.apple.com/us/app/dive-brain-health/id1573350395',
  ANDROID: 'https://play.google.com/store/apps/details?id=com.divergenceneuro.mobileapp',
};

export const CHAT_BOT_INFO = {
  initialText:
    "Hi! My name is Venus. I'm an AI assistant here to help you with any question you might have about the Divergence platform and the supported EEG headsets. When asking me a question, please be as specific as you can using any terminology you may have seen in the platform. I'll do my best to answer your questions but please keep in mind that I'm still learning. What can I help you with?",
  failureText:
    "I'm sorry, it’s taking longer than usual for me to think about this. Please wait for a minute and try your question again.",
  endSessionText:
    "Great! I'm glad I could be of assistance! Just let me know if there are any other questions you might have.",
  question: 'Did that answer your question?',
  poorAnswer:
    "I'm sorry that I didn't fully answer your question. Could you please provide some additional details about your question for me?",
};
